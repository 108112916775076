import * as React from 'react';
import { Global, css } from '@emotion/react';
import NeueMachinaBlack from '../assets/fonts/NeueMachina/NeueMachina-Black.otf';
import NeueMachinaBold from '../assets/fonts/NeueMachina/NeueMachina-Bold.otf';
import NeueMachinaLight from '../assets/fonts/NeueMachina/NeueMachina-light.otf';
import NeueMachinaMedium from '../assets/fonts/NeueMachina/NeueMachina-Medium.otf';
import NeueMachinaRegular from '../assets/fonts/NeueMachina/NeueMachina-Regular.otf';
import NeueMachinaUltraBold from '../assets/fonts/NeueMachina/NeueMachina-Ultrabold.otf';
import NeueMachinaUltraLight from '../assets/fonts/NeueMachina/NeueMachina-Ultralight.otf';

export const KARLA_GOOGLE_FONT_IMPORT = 'https://fonts.googleapis.com/css2?family=Karla:ital@0;1&display=swap';

export const Fonts = () => (
  <Global
    styles={css`
      @import url('${KARLA_GOOGLE_FONT_IMPORT}');
      // Neue Machina uses static fonts
      @font-face {
        font-family: 'Neue Machina';
        font-weight: 200;
        src: url(${NeueMachinaUltraLight});
      }
      @font-face {
        font-family: 'Neue Machina';
        font-weight: 300;
        src: url(${NeueMachinaLight});
      }
      @font-face {
        font-family: 'Neue Machina';
        font-weight: 400;
        src: url(${NeueMachinaRegular});
      }
      @font-face {
        font-family: 'Neue Machina';
        font-weight: 500;
        src: url(${NeueMachinaMedium});
      }
      @font-face {
        font-family: 'Neue Machina';
        font-weight: 700;
        src: url(${NeueMachinaBold});
      }
      @font-face {
        font-family: 'Neue Machina';
        font-weight: 800;
        src: url(${NeueMachinaUltraBold});
      }
      @font-face {
        font-family: 'Neue Machina';
        font-weight: 900;
        src: url(${NeueMachinaBlack});
      }
    `}
  />
);
